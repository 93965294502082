<template>
  <b-card>
    <b-card-text>
      <div v-html="htmlData"></div>
    </b-card-text>
  </b-card>
</template>

<script>
import axios from "axios";

export default {
  name: "SummaryReportsView",
  data() {
    return {
      htmlData: '',
    }
  },
  mounted() {
    let viewType = this.$route.params.viewType;
    let url='';
    if(viewType=='MembershipCountSummary'){
      url = this.basePath+'api/report/membership-with-division/view';
    }
    else if(viewType=='SummaryByReportingGroupAndType'){
      url = this.basePath+'api/report/membership-counts/view';
    }
    else if(viewType == 'SummaryByReportingGroup'){
      url = this.basePath+'api/report/membership-with-division/view/1';
    }
    axios.get(url).then((res) => {
      this.htmlData = res.data;
    });
  }
}
</script>

<style scoped>

</style>